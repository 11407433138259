import dayjs, { Dayjs } from 'dayjs'
import { CFG, LABELS, getProjectName, getUserByUname } from '~/services/cfg'
import {
  getIssue,
  isValidLabelEvent,
  updateAssignees,
  updateDescription,
  updateDueDate,
  updateTitle,
} from '..'
import {
  WORKFLOW_STATE_LABELS,
  extractIssueDataFromDesc,
  getUpdatedDescription,
  type WorkflowState,
} from '../mapping'
import {
  Issue,
  SprintIssue,
  SprintTechIssue,
  TechIssue,
  batchLoadDiscussions,
  batchLoadLabelEvents,
  batchLoadNotes,
  batchLoadWeightEvents,
  batchUpdateLabels,
  loadBlockedIssues,
} from '.'
import { humanDurationToSp } from '~/utils/dates'
import {
  type DiscussionR,
  discussionR,
} from '~/api/discussions/models/Discussion'
import { createDiscussion } from '~/api/discussions'
import { WithDebounce } from '~/api/common/models/WithDebounce'

type Priority = 'Low' | 'Medium' | 'High'

const teamLabelsMap = mapValues((t) => t.glLabel, CFG.teams)
const teamLabels = Object.values(teamLabelsMap)

const loadBlockedByBatch = semaphoreBuilder()(loadBlockedIssues)

// assigned to @tommasogangemi and unassigned @a.basile
// assigned to @a.basile
// unassigned @a.basile
const ASSIGNMENT_BODY_CATCHER = /^(unassigned|assigned to)\s@([^@\s]*)/
const SPENT_TIME_BODY_CATCHER =
  /^(subtracted|deleted|added)((\s\d+h)?(\s\d+m)?)\sof (time spent$|spent time)/
// /^(subtracted|added)((\s\d+h)?(\s\d+m)?)\sof time spent$/

const createdTillDay = (day: Dayjs) => (item: { created_at: string }) =>
  !dayjs(item.created_at).isAfter(day, 'day')

const computeLabels = (labelEvents: LabelEvent[]) => {
  const labels: string[] = []
  labelEvents.forEach((e) => {
    if (e.action === 'add') {
      if (!e.label) {
        debugger
      }
      labels.push(e.label.name)
    } else {
      const index = labels.indexOf(e.label.name)
      if (index > -1) {
        labels.splice(index, 1)
      }
    }
  })
  return labels
}

const extractCustomData = (desc?: string) =>
  extractIssueDataFromDesc(
    (): IssueCustomData => ({
      notes: '',
    }),
    desc,
  )

export abstract class IssueBase extends WithDebounce {
  private _blockedBy?: GLIssueLink[]

  constructor(protected _fromApi: GLIssue) {
    super()
  }

  async loadBlocking(force = false) {
    const blockedBy = await (force
      ? loadBlockedIssues(this.projectId, this.iid, true)
      : loadBlockedByBatch(this.projectId, this.iid))
    if (!this._blockedBy || force) this._blockedBy = blockedBy
    return this._blockedBy
  }

  private _discussions?: DiscussionR[]
  private _notes?: GLNote[]
  private _weightEvents?: GLWeightEvent[]
  private _labelEvents?: GLLabelEvent[]

  // TODO: move notes, assignment and label management to tech issues only
  // this should not be in general issues since its state should just
  // be computed from tech issues!

  // #region notes management (assignments, spent time)
  async loadDiscussions(force: boolean = false) {
    const discussions = await batchLoadDiscussions(
      this.projectId,
      this.iid,
      force,
    )
    if (!force && this._discussions !== undefined) return
    this._discussions = discussions.map((d) => discussionR(d, this))
  }

  async createDiscussion(text: string) {
    const discussion = await createDiscussion(this.projectId, this.iid, text)
    this._discussions?.push(discussionR(discussion, this))
  }

  get discussions() {
    // implicit side effect
    this.loadDiscussions()
    return this._discussions ?? []
  }
  get openPlanningDiscussions() {
    return this.discussions.filter((d) => d.isPlanningDiscussion && d.isOpen)
  }

  get lastDiscussionNote() {
    return this.openPlanningDiscussions.last()?.lastNote
  }

  get lastDiscussionNoteSummary() {
    return this.lastDiscussionNote
      ? `${this.lastDiscussionNote.author.name} wrote: '${
          this.lastDiscussionNote.preview
        }' at ${this.lastDiscussionNote.createdAt.toDateTime()}`
      : undefined
  }

  async loadNotes(force: boolean = false) {
    const notes = await batchLoadNotes(this.projectId, this.iid, force)
    if (!force && this._notes !== undefined) return
    this._notes = notes
  }

  private get parsedNotes() {
    // implicit side effect
    this.loadNotes()
    return (this._notes ?? []).map((n) => ({
      id: n.id,
      createdAt: dayjs(n.created_at),
      body: n.body,
      author: n.author,
      assignmentMatch: n.body.match(ASSIGNMENT_BODY_CATCHER),
      spentTimeMatch: n.body.match(SPENT_TIME_BODY_CATCHER),
    }))
  }

  get assignmentEvents() {
    return this.parsedNotes
      .filter((n) => n.assignmentMatch)
      .map(({ id, createdAt, author, body, assignmentMatch }) => {
        const isAssignment = body.startsWith('assigned')
        const username = assignmentMatch![2]
        return {
          id,
          createdAt,
          author,
          assignee:
            isAssignment && username ? getUserByUname(username) : undefined,
        }
      })
  }

  get spentTimeEvents() {
    return this.parsedNotes
      .filter((n) => n.spentTimeMatch)
      .map(({ id, createdAt, author, body, spentTimeMatch }) => {
        const operation = spentTimeMatch![1] as
          | 'subtracted'
          | 'added'
          | 'deleted'
          | undefined
        const humanDuration = spentTimeMatch![2]
        if (!operation || !humanDuration) {
          throw new Error(`invalid spent time note: ${body}`)
        }
        const totSpentSP =
          humanDurationToSp(humanDuration) * (operation === 'added' ? 1 : -1)
        return {
          id,
          createdAt,
          author,
          spent: totSpentSP,
        }
      })
  }
  // #endregion

  // #region weight management
  async loadWeightEvents(force: boolean = false) {
    const weightEvents = await batchLoadWeightEvents(
      this.projectId,
      this.iid,
      force,
    )
    if (!force && !!this._weightEvents) return
    this._weightEvents = weightEvents
  }

  get weightEvents() {
    // implicit side effect
    this.loadWeightEvents()
    return (
      this._weightEvents?.map(({ created_at, id, user, weight }) => ({
        id,
        createdAt: dayjs(created_at),
        author: user,
        weight,
      })) ?? []
    )
  }
  // #endregion

  // #region label management
  async loadLabelEvents() {
    const labelEvents = await batchLoadLabelEvents(this.projectId, this.iid)
    if (!this._labelEvents) {
      this._labelEvents = labelEvents
    }
    return this._labelEvents
  }

  get labelEvents() {
    // implicit side effect
    this.loadLabelEvents()
    return this._labelEvents?.filter(isValidLabelEvent) ?? []
  }
  // #endregion

  getLabelsToDate = (date: Dayjs) => {
    const labelEvents =
      this.labelEvents?.filter((e) => createdTillDay(date)(e)) ?? []
    return computeLabels(labelEvents)
  }

  isOverToDate(date?: Dayjs) {
    return this.getLabelsToDate(date ?? dayjs()).includes(LABELS.readyForProd)
  }

  /**
   * given a collection of possible values and selected values, returns the updated labels
   * removing non selected possible values and adding selected values
   * @param possibleValues
   * @param selected
   */
  private getUpdatedLabels(
    possibleValues: readonly string[],
    selected: readonly string[],
  ) {
    const labels = this._fromApi.labels.filter(
      (l) => !possibleValues.includes(l),
    )
    labels.push(...selected)
    return labels
  }

  protected getUpdatedWorkflowLabels(newWorkflowState: WorkflowState) {
    return this.getUpdatedLabels(WORKFLOW_STATE_LABELS, [newWorkflowState])
  }

  protected async persistLabels(labels: string[]) {
    const updated = await batchUpdateLabels(this.projectId, this.iid, labels)
    this._fromApi.labels = updated.labels
  }

  /**
   * @deprecated not a full deprecation: this is ok as protected method => publicly use just more specific methods (i.e: setTeam)
   * @param collection
   * @param selected
   */
  async updateAndPersistLabels(
    collection: readonly string[],
    selected: readonly string[],
  ) {
    const labels = this.getUpdatedLabels(collection, selected)
    if (!labels.equals(this._fromApi.labels, undefined, false)) {
      await this.persistLabels(labels)
    }
  }

  setTeam(teamName?: TeamName) {
    return this.updateAndPersistLabels(
      teamLabels,
      teamName ? [CFG.teams[teamName].glLabel] : [],
    )
  }

  updateWorkflowState(newWorkflowState: WorkflowState) {
    return this.updateAndPersistLabels(
      WORKFLOW_STATE_LABELS,
      this.getUpdatedWorkflowLabels(newWorkflowState),
    )
  }

  setNth(nth: boolean) {
    return this.updateAndPersistLabels([LABELS.nth], nth ? [LABELS.nth] : [])
  }

  get dueDate() {
    if (!this._fromApi.due_date) return
    return dayjs(this._fromApi.due_date)
  }

  async setDueDate(dueDate?: Date | null) {
    const updated = await updateDueDate(
      this.projectId,
      this.iid,
      dueDate ? dayjs(dueDate) : null,
    )
    this._fromApi.due_date = updated.due_date
  }

  get blockedByLinks() {
    // side effect implicit load
    this.loadBlocking()
    return this._blockedBy
  }

  get createdAt() {
    return dayjs(this._fromApi.created_at)
  }

  get updatedAt() {
    return dayjs(this._fromApi.updated_at)
  }

  get state() {
    return this._fromApi.state
  }

  get isClosed() {
    return this.state === 'closed'
  }

  get projectId() {
    return this._fromApi.project_id
  }

  get projectName() {
    return getProjectName(this.projectId)
  }

  get id() {
    return this._fromApi.id
  }

  get iid() {
    return this._fromApi.iid
  }

  get title() {
    return this._fromApi.title
  }

  async setTitle(title: string, debounced = false) {
    const updated = await this.queue(
      'setTitle',
      () => updateTitle(this.projectId, this.iid, title),
      debounced,
    )
    if (updated) this._fromApi.title = updated.title
  }

  get description() {
    return this._fromApi.description
  }

  get assignee() {
    return this._fromApi.assignees[0]
  }

  get assignees() {
    return this._fromApi.assignees
  }

  async setAssignees(assigneeIds: number[]) {
    const promises: [Promise<GLIssue>, Promise<void>?] = [
      updateAssignees(this.projectId, this.iid, assigneeIds),
    ]
    // se STO non è parte degli assignees, lo rimuovo
    if (this.sto && !assigneeIds.includes(this.sto.id)) {
      promises.push(this.setCustomData('sto', undefined))
    }
    const [updated] = await Promise.all(promises)
    this._fromApi.assignees = updated!.assignees
  }
  addAssignee(assigneeId: number) {
    return this.setAssignees([...this.assignees.map((a) => a.id), assigneeId])
  }

  get sto() {
    return this.assignees.find((a) => a.id === this.customData.sto)
  }

  setSto(stoId?: number) {
    const promises: Promise<unknown>[] = []
    promises.push(this.setCustomData('sto', stoId))
    // se STO non è parte degli assignees, lo aggiungo agli assignees
    if (stoId && !this.assignees.some((a) => a.id === stoId)) {
      promises.push(this.addAssignee(stoId))
    }
    return Promise.all(promises)
  }

  get author() {
    return this._fromApi.author
  }

  get webUrl() {
    return this._fromApi.web_url
  }

  get labels() {
    return this._fromApi.labels
  }

  get workflowState() {
    return this.labels.find((l) => includes(WORKFLOW_STATE_LABELS, l)) as
      | WorkflowState
      | undefined
  }

  get priority(): Priority | undefined {
    return this.labels.includes(LABELS.lowPrio)
      ? 'Low'
      : this.labels.includes(LABELS.hiPrio)
      ? 'High'
      : this.labels.includes(LABELS.midPrio)
      ? 'Medium'
      : undefined
  }

  setPriority(priority: Priority) {
    return this.updateAndPersistLabels(
      [LABELS.lowPrio, LABELS.midPrio, LABELS.hiPrio],
      [
        priority === 'Low'
          ? LABELS.lowPrio
          : priority === 'High'
          ? LABELS.hiPrio
          : LABELS.midPrio,
      ],
    )
  }

  abstract get weight(): number | undefined
  // abstract get techDebts(): TechDebt[]

  /**
   * Here we have the temporary solution to quickly handle single-team current situation:
   * defaults to 'private' if no team label is found
   *
   * Whenever a new team is added, this method should be updated together
   * with other logic throughout the codebase.
   */
  get teamName(): TeamName | undefined {
    return (
      entries(CFG.teams).find(
        ([_teamName, team]) => this._fromApi.labels.includes(team.glLabel),
        // )?.[0]
      )?.[0] ?? 'private'
    )
  }

  get isNth() {
    return this._fromApi.labels.includes(LABELS.nth)
  }

  // CUSTOM DATA MANAGEMENT

  protected get customData() {
    return extractCustomData(this._fromApi.description)
  }

  get notes() {
    return this.customData.notes
  }

  protected async setCustomData<TField extends keyof IssueCustomData>(
    field: TField,
    value: IssueCustomData[TField],
    debounced = false,
  ) {
    const updated = await this.queue(
      'setCustomData',
      async () => {
        // get the last version of the issue to avoid conflicts
        const last = await getIssue(this.projectId, this.iid)
        const lastCustomData = extractCustomData(last.description)
        // selectively override just the field we want to update
        lastCustomData[field] = value

        return updateDescription(
          this.projectId,
          this.iid,
          getUpdatedDescription(lastCustomData, last.description),
        )
      },
      debounced,
    )
    if (updated) this._fromApi.description = updated.description
  }

  // some useful shortcuts as TS type guards
  isIssue(): this is Issue {
    return this instanceof Issue
  }
  isTechIssue(): this is TechIssue {
    return this instanceof TechIssue
  }
  isSprintIssue(): this is SprintIssue {
    return this instanceof SprintIssue
  }
  isSprintTechIssue(): this is SprintTechIssue {
    return this instanceof SprintTechIssue
  }

  setNotes(notes: string, debounced = false) {
    return this.setCustomData('notes', notes, debounced)
  }

  // END LABELS MANAGEMENT
}
