<template>
  <div>
    <DialogCenter />
    <NotificationCenter />
    <ProgressBar />
    <!-- <NuxtWelcome /> -->
    <v-card>
      <v-layout>
        <v-app-bar class="bg-primary">
          <RoutingBtns />

          <v-spacer></v-spacer>

          <a
            href="https://gitlab.com/kampaay/scrum/-/issues/?sort=label_priority&state=opened&first_page_size=20"
            target="_blank"
            class="text-white"
            ><v-btn icon> <v-icon>mdi-bug</v-icon></v-btn>
            <v-tooltip
              activator="parent"
              :text="`Quickly report a bug or suggest a feature here!
just click on 'New Issue' (if nothing similar has been reported yet) and tag whoever you think should be aware of it!`"
              :content-props="{ style: 'white-space: pre-wrap' }"
            />
          </a>

          <!-- 
                        FOLLOWING TEMPLATE GIVES THIS VUE WARNING:
                        [Vue warn]: Slot "default" invoked outside of the render function: this will not track dependencies used in the slot. Invoke the slot function inside the render function instead. 
                     -->

          <!-- <v-tooltip
                        v-if="me !== undefined"
                        :text="`OMG! ${me.name} is here 😎`"
                    >
                        <template v-slot:activator="{ props }">
                            <v-avatar  v-bind="props" class="mr-3 pointer">
                                <v-img :src="me.avatar_url" />
                            </v-avatar>
                        </template>
                    </v-tooltip> -->

          <v-menu :close-on-content-click="true">
            <template v-slot:activator="{ props }">
              <WithTooltip
                v-if="me !== undefined"
                :tooltip="`OMG! ${
                  isGuest ? 'an amazing Guest' : me.name
                } is here 😎`"
              >
                <v-avatar class="mr-3 pointer" v-bind="props">
                  <v-icon v-if="isGuest" color="secondary"
                    >mdi-account-question</v-icon
                  >
                  <v-img v-else :src="me.avatarUrl" />
                </v-avatar>
              </WithTooltip>
            </template>

            <v-card>
              <v-list>
                <v-list-item @click="toggleRole" color="primary">
                  <v-list-item-title>{{
                    isGuest ? 'Login as Engineering member' : 'Browse as Guest'
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-app-bar>

        <v-main>
          <v-container fluid>
            <NuxtPage v-if="ready" />
            <div v-else>loading...</div>
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useLoadRuntimeCfg } from '~/services/runtimeCfg'
import { initMe, type TMe } from '~/api/user/models/Me'
import NotificationCenter from '~/components/notifications/NotificationCenter.vue'
import { isGuest, toggleRole } from '~/services/guestManagement'
import RoutingBtns from '~/components/RoutingBtns.vue'

useLoadRuntimeCfg()

const me = ref<TMe | undefined>(undefined)

const ready = computed(() => me.value !== undefined)

initMe().then((u) => {
  me.value = u
})
</script>
